import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';

import { Title, Copy } from './item.css';
import { Link } from '@reach/router';
const bannerBaseUri = 'https://dxscfd0n17jvm.cloudfront.net/gamesb/';
const defaultBanner = require('../../../images/game_banner_placeholder.png');

const Item = ({ title, url, platforms, description, weburl }) => {
  const redirectToGame = e => {
    navigate(`/app/${weburl}/`, {
      state: { title, url, platforms, description, weburl },
    });
  };
  let bgImage = url ? `${bannerBaseUri + url}` : defaultBanner;
  return (
    <figure>
      <button
        style={{ backgroundColor: '#FFF', border: 0, cursor: 'pointer' }}
        onClick={e => redirectToGame(e)}
      >
        <img src={bgImage} alt={title} width="100%" />

        <figcaption>
          <Title>{title}</Title>
        </figcaption>
      </button>
    </figure>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};
export default Item;
